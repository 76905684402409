import { graphql } from 'gatsby';
import React from 'react';

import { getPageModularPath } from '../common/link';
import { Navigation } from '../components/core/navigation';
import { PageLayout } from '../components/layout/page';
import { AnchorProvider, AnchorType } from '../context/anchor';
import { useLocale } from '../context/locale';

export const query = graphql`
  query PageModularQuery($id: String!, $originalId: String!) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsPageModular(id: { eq: $id }) {
      model {
        apiKey
      }
      locale
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      inPageNavigation
      title
      body {
        ...ModuleTextAndImage
        ...ModuleText
        ...ModuleImage
        ...ModuleHeading
        ...ModuleGallery
        ...ModuleExpandingList
        ...ModuleHero
        ...ModuleNumberedList
        ...ModuleQuote
        ...ModuleValuesList
        ...ModulePositionsList
        ...ModuleDownloadList
        ...ModuleContact
        ...ModuleLogoBlock
        ...ModuleIntro
        ...ModuleLatestNews
        ...ModuleUpdateList
        ...ModuleLatestUpdateList
      }
    }
    versions: allDatoCmsPageModular(
      filter: { slug: { ne: null }, originalId: { eq: $originalId } }
    ) {
      nodes {
        slug
        locale
        model {
          apiKey
        }
      }
    }
  }
`;

const PageModular = ({ data, pageContext }) => {
  const page = data.page;
  const { slug } = page;
  const locale = useLocale();

  const navigationItems: AnchorType[] = page.inPageNavigation
    ? page.body
        .filter((block) => !!block.anchor)
        .map((block, idx) => ({
          indexNumber: idx + 1,
          id: block.anchor,
          text: block.text,
        }))
    : [];

  return navigationItems.length ? (
    <AnchorProvider allAnchors={navigationItems}>
      <Navigation
        anchors={navigationItems}
        url={getPageModularPath({ locale, slug })}
      />
      <PageLayout data={data} pageContext={pageContext} />
    </AnchorProvider>
  ) : (
    <PageLayout data={data} pageContext={pageContext} />
  );
};

export default PageModular;
