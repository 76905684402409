import React, { useState, useEffect, MouseEvent } from 'react';

import { useVisibleAnchors } from '../../../context/anchor';
import { getNumber } from '../../../utils/getNumber';

import {
  AnchorsList,
  AnchorItem,
  Link,
  ActiveAnchor,
  Index,
} from './navigation.styles';
import { NavigationProps } from './navigation.types';

export const Navigation = ({ anchors, url }: NavigationProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const visibleAnchors = useVisibleAnchors();

  const close = () => setIsOpened(false);
  const open = (e: MouseEvent) => {
    e.stopPropagation();
    setIsOpened(true);
  };

  useEffect(() => {
    window.addEventListener('click', close);

    return () => {
      window.removeEventListener('click', close);
    };
  }, []);

  const activeAnchor = visibleAnchors[visibleAnchors.length - 1];

  return (
    <AnchorsList>
      {anchors.map((item) => {
        return (
          <AnchorItem key={item.id} isOpened={isOpened}>
            <Link
              to={`${url}#${item.id}`}
              onAnchorLinkClick={close}
              active={item.id === activeAnchor?.id}
            >
              <Index>{getNumber(item.indexNumber)}</Index>
              {item.text}
            </Link>
          </AnchorItem>
        );
      })}
      {activeAnchor ? (
        <ActiveAnchor onClick={open}>
          <Index>{getNumber(activeAnchor.indexNumber)}</Index>
          {activeAnchor.text}
        </ActiveAnchor>
      ) : null}
    </AnchorsList>
  );
};
