import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled from 'styled-components';

import { Color, getColor } from '../../../theme/color';
import {
  ScreenSize,
  getMediaQuery,
  getUnitAsPixels,
  borderRadius,
} from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';

export const AnchorsList = styled.div`
  position: fixed;
  bottom: ${getUnitAsPixels({ multiplier: 2 })};
  left: ${getUnitAsPixels({ multiplier: 2 })};
  z-index: 10;
  pointer-events: none;

  ${getMediaQuery(ScreenSize.MEDIUM)} {
    bottom: ${getUnitAsPixels({ multiplier: 3 })};
    left: ${getUnitAsPixels({ multiplier: 3 })};
  }
`;

export const AnchorItem = styled.div<{ isOpened: boolean }>`
  display: flex;
  margin-bottom: ${getUnitAsPixels()};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(28px);
  border-radius: ${borderRadius};
  opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
  visibility: ${({ isOpened }) => (isOpened ? 'visible' : 'hidden')};
  transition: all 0.3s ease-in-out;
  pointer-events: all;
`;

export const Link = styled(AnchorLink)`
  display: block;
  padding: ${getUnitAsPixels()} ${getUnitAsPixels({ multiplier: 2 })};
  ${getTypography(Type.BODY_SMALL)};
  color: ${getColor(Color.BLACK)};
  opacity: ${({ active }: { active: boolean }) => (active ? '1' : '0.3')};
  text-decoration: none;
  transition: opacity 0.2s ease;
  width: 100%;

  &:hover {
    opacity: 1;
  }
`;

export const ActiveAnchor = styled.div`
  cursor: pointer;
  padding: ${getUnitAsPixels()} ${getUnitAsPixels({ multiplier: 2 })};
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(28px);
  border-radius: ${borderRadius};
  ${getTypography(Type.BODY_SMALL)};
  color: ${getColor(Color.BLACK)};
  transition: all 0.2s ease;
  pointer-events: all;

  &:hover {
    background: rgba(242, 242, 242, 0.7);
    backdrop-filter: blur(17px);
  }
`;

export const Index = styled.span`
  padding-right: ${getUnitAsPixels()};
`;
